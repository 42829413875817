<template>
  <v-app>
    <v-main>
      <v-container
        fill-height
        fluid
        :style="`
          background-image: url(${require('@/assets/bday.jpg')});
          background-position: center;
          background-size: cover;`"
      >
        <v-row align="center" justify="center">
          <v-col align="center">
            <h1
              style="
                color: white;
                border: 2px solid white;
                display: inline-block;
                padding: 2px 8px;
              "
            >
              The UnusualBeaut
            </h1>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
};
</script>
